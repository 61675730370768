import type { JSXElementConstructor, ReactElement, ReactNode, ReactPortal } from "react";
import { useEffect, useRef, useState } from "react";
import { flushSync } from "react-dom";
import { cloneDeep } from "lodash";
import type { ActionType, ProColumns, ProFormInstance } from "@ant-design/pro-components";
import { ProTable } from "@ant-design/pro-components";
import {
  Button,
  Checkbox,
  Input,
  Space,
  Modal,
  Image,
  Tooltip,
  message,
  Popconfirm,
  Form
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import EditSortInput from "../components/EditSortInput";
import moment from "moment";
import { getLocale, useIntl } from "umi";
import userManage from "@/api/userManage";
import { handlePostData } from "./common";
import styles from "./index.less";
import "./index.less";
import type { RenderFunction } from "antd/lib/tooltip";
import type { TabKey } from ".";
import ImportButton from "./ImportButton";
import {
  currencyUnit,
  formatPrice,
  formatPriceMillennials,
  formattHousandth
} from "@/utils/format";
import { SelectProductsModal } from "../components/SelectProductsModal";

interface Props {
  current: any;
  tabKey: TabKey;
  focusTabKey: TabKey;
  onUpdateTabTitles?: () => void;
  onShareProductReady?: (product: any) => void;
}

export const Inner = ({
  current,
  tabKey,
  focusTabKey,
  onUpdateTabTitles,
  onShareProductReady
}: Props) => {
  const actionRef = useRef<ActionType>();
  const formRef = useRef<ProFormInstance>();
  const intl = useIntl();
  const [modal, contextHolder] = Modal.useModal();

  const [searchWord, setSearchWord] = useState<string>("");
  const [productList, setProductList] = useState<any>([]);
  const originProductListRef = useRef<any>([]);
  const [selectProductIds, setSelectProductIds] = useState<any>([]);

  const [delLoading, setDelLoading] = useState<boolean>(false);
  const [delItemLoading, setDelItemLoading] = useState<boolean>(false);
  const [putOnLoading, setPutOnLoading] = useState(false);

  const [addProductsModalVisible, setAddProductsModalVisible] = useState(false);
  const [addProductsButtonLoading, setAddProductsButtonLoading] = useState(false);

  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    console.log("focusTabKey:", focusTabKey, tabKey);
    if (focusTabKey === tabKey) {
      actionRef.current?.reload();
    }
  }, [focusTabKey]);

  const handleSelectRowChange = (e: any, record: any) => {
    if (e.target.checked) {
      setSelectProductIds(selectProductIds.concat(record.id));
    } else {
      setSelectProductIds(selectProductIds.filter((item: any) => item !== record.id));
    }
  };

  const handleAllCheckedChange = (e: any) => {
    if (e.target.checked) {
      setSelectProductIds(productList.map((item: any) => item.id));
    } else {
      setSelectProductIds([]);
    }
  };

  const handleAddProducts = () => {
    window.open("/product-manage/tt-products/normal");
  };

  const handlePutOnRow = (record: any) => {
    return new Promise((resolve) => {
      setPutOnLoading(true);
      userManage
        .requestEffectiveItemSetProduct({
          type: 1,
          itemSetId: current.id || "",
          itemIdList: [record.id || ""]
        })
        .then((result: any) => {
          if (result.code === 200) {
            setSelectProductIds([]);
            actionRef.current?.reload();

            if (result.result.failedCount === 0) {
              message.success(
                intl.formatMessage(
                  { id: "collection.count个商品均已上架！" },
                  {
                    count: result.result.successCount
                  }
                )
              );
            } else {
              message.warning(
                intl.formatMessage(
                  {
                    id: "collection.successCount个商品已上架，failedCount个商品已失效，请前往失效商品查看！"
                  },
                  {
                    successCount: result.result.successCount,
                    failedCount: result.result.failedCount
                  }
                )
              );
            }

            onUpdateTabTitles?.();
          }
        })
        .catch((err: any) => {
          console.log(err);
        })
        .finally(() => {
          setPutOnLoading(false);
          resolve(null);
        });
    });
  };

  const handleDeleteRow = (record: any) => {
    return new Promise((resolve) => {
      setDelItemLoading(true);
      userManage
        .requestUpdateItemSetProduct({
          type: 0,
          itemSetId: current.id || "",
          itemIdList: [record.id || ""]
        })
        .then((result: any) => {
          if (result.code === 200) {
            setSelectProductIds([]);
            actionRef.current?.reload();
            message.success(intl.formatMessage({ id: "ttSample.删除成功！" }));
            onUpdateTabTitles?.();
          }
        })
        .catch((err: any) => {
          console.log(err);
        })
        .finally(() => {
          setDelItemLoading(false);
          resolve(null);
        });
    });
  };

  const columns = [
    {
      forTabKeys: ["valid", "invalid", "pending"],
      title: (
        <div className={styles.no_container}>
          <Checkbox
            disabled={productList.length === 0}
            checked={selectProductIds.length >= productList.length}
            onChange={handleAllCheckedChange}
          />
          <div className={styles.no}>{intl.formatMessage({ id: "ttSample.序号" })}</div>
        </div>
      ),
      dataIndex: "no",
      hideInSearch: true,
      align: "center",
      width: 100,
      renderText(_text: any, record: { id: any }, index: number, _action: any) {
        return (
          <div className={styles.no_container}>
            <Checkbox
              checked={selectProductIds.includes(record.id)}
              onChange={(e) => handleSelectRowChange(e, record)}
            />
            <div className={styles.no}>{(pageNo - 1) * pageSize + index + 1}</div>
          </div>
        );
      }
    },
    {
      forTabKeys: ["valid", "invalid", "pending"],
      hideInSearch: true,
      title: (
        <div style={{ textAlign: "center" }}>{intl.formatMessage({ id: "common.TT商品" })}</div>
      ),
      key: "tt_product",
      width: 440,
      renderText: (
        _text: any,
        record: {
          image: string | undefined;
          productName:
            | string
            | number
            | boolean
            | ReactElement<any, string | JSXElementConstructor<any>>
            | Iterable<ReactNode>
            | RenderFunction
            | null
            | undefined;
          productId:
            | string
            | number
            | boolean
            | ReactElement<any, string | JSXElementConstructor<any>>
            | Iterable<ReactNode>
            | ReactPortal
            | null
            | undefined;
          campaignId:
            | string
            | number
            | boolean
            | ReactElement<any, string | JSXElementConstructor<any>>
            | Iterable<ReactNode>
            | ReactPortal
            | null
            | undefined;
        },
        _index: any,
        _action: any
      ) => {
        return (
          <div className={styles.tt_product}>
            <Image className={styles.thumbnail} width={44} height={44} src={record.image} />
            <div className={styles.content_container}>
              <Tooltip title={record.productName}>
                <div className={styles.title}>{record.productName as string}</div>
              </Tooltip>
              <div className={styles.desc} style={{ color: "#6E6E6E" }}>
                {intl.formatMessage({ id: "item.ItemId" })}:{record.productId}
              </div>
              <div className={styles.desc}>
                {intl.formatMessage({ id: "active.ActiveId" })}:{record.campaignId}
              </div>
            </div>
          </div>
        );
      }
    },
    {
      forTabKeys: ["valid", "pending"],
      hideInSearch: true,
      title: (
        <div style={{ textAlign: "center" }}>
          {intl.formatMessage({ id: "active.ActivePrice" })}
        </div>
      ),
      width: 140,
      renderText: (
        _text: any,
        record: { minPrice: any; maxPrice: any },
        _index: any,
        _action: any
      ) => {
        return `${currencyUnit(formatPriceMillennials(record.minPrice))}-${currencyUnit(
          formatPriceMillennials(record.maxPrice)
        )}`;
      },
      align: "center"
    },
    {
      forTabKeys: ["valid", "pending"],
      hideInSearch: true,
      title: (
        <div style={{ textAlign: "center" }}>
          {intl.formatMessage({ id: "item.CreatorCommission" })}
        </div>
      ),
      width: 140,
      renderText: (_text: any, record: any, _index: any, _action: any) => {
        return `${currencyUnit(formatPriceMillennials(record.minEarn))}-${currencyUnit(
          formatPriceMillennials(record.maxEarn)
        )}`;
      },
      align: "center"
    },
    {
      forTabKeys: ["valid", "pending"],
      hideInSearch: true,
      title: (
        <div style={{ textAlign: "center" }}>
          {intl.formatMessage({ id: "item.CreatorCommissionRate" })}
        </div>
      ),
      width: 128,
      renderText: (_text: any, record: { commissionRate: any }, _index: any, _action: any) => {
        return record.commissionRate || "-";
      },
      align: "center"
    },
    {
      forTabKeys: ["valid", "pending"],
      hideInSearch: true,
      title: (
        <div style={{ textAlign: "center" }}>{intl.formatMessage({ id: "item.Inventory" })}</div>
      ),
      dataIndex: "stock",
      width: 100,
      align: "center",
      renderText: (_text: any, record: any, _index: any, _action: any) => {
        return formattHousandth(record.stock || 0);
      }
    },
    {
      forTabKeys: ["valid", "pending"],
      hideInSearch: true,
      title: (
        <div style={{ textAlign: "center" }}>{intl.formatMessage({ id: "item.30天销量" })}</div>
      ),
      width: 128,
      dataIndex: "salesForLast30Days",
      renderText: (text: any, _record: any, _index: any, _action: any) =>
        formattHousandth(text || 0),
      align: "center"
    },
    {
      forTabKeys: ["valid", "pending"],
      hideInSearch: true,
      title: (
        <div style={{ textAlign: "center" }}>{intl.formatMessage({ id: "item.TikTok总销量" })}</div>
      ),
      width: 148,
      renderText: (_text: any, record: { sales: any }, _index: any, _action: any) =>
        formattHousandth(record.sales || 0),
      align: "center"
    },
    {
      forTabKeys: ["valid", "pending"],
      hideInSearch: true,
      title: (
        <div style={{ textAlign: "center" }}>
          {intl.formatMessage({ id: "ttSample.近2周销售增长%" })}
        </div>
      ),
      width: 148,
      dataIndex: "salesForTwoWeeksRate",
      align: "center"
    },
    {
      forTabKeys: ["valid", "pending"],
      hideInSearch: true,
      title: (
        <div style={{ textAlign: "center" }}>
          {intl.formatMessage({ id: "item.merchantSubsidies" })}
        </div>
      ),
      width: 312,
      renderText(_text: any, record: {}, _index: any, _action: any) {
        // @ts-ignore
        const { itemSubsidyDTO } = record || {};
        const { titleEn, titleTh, descriptionEn, descriptionTh, startTime, endTime } =
          itemSubsidyDTO || {};
        return !itemSubsidyDTO ? (
          "-"
        ) : (
          <div className={styles.subsidy_container}>
            <div className={styles.subsidy_item}>{getLocale() === "th-TH" ? titleTh : titleEn}</div>
            <div className={styles.subsidy_item}>
              {getLocale() === "th-TH" ? descriptionTh : descriptionEn}
            </div>
            <div className={styles.subsidy_item}>{`${moment(startTime || 0).format(
              "YYYY/MM/DD HH:mm:ss"
            )}-${moment(endTime || 0).format("YYYY/MM/DD HH:mm:ss")}`}</div>
          </div>
        );
      },
      align: "center"
    },
    {
      forTabKeys: ["valid", "invalid", "pending"],
      key: "queryParam",
      width: 1,
      fieldProps: {
        placeholder: intl.formatMessage({ id: "ttSample.请输入商品ID或商品名称" })
      }
    },
    {
      forTabKeys: ["valid"],
      hideInSearch: true,
      title: (
        <div style={{ textAlign: "center" }}>{intl.formatMessage({ id: "ttSample.Sort" })}</div>
      ),
      width: 148,
      align: "center",
      fixed: "right",
      renderText(
        _text: any,
        record: { id: number; itemSort: number },
        index: number,
        _action: any
      ) {
        return (
          <EditSortInput
            key={record.id + Date.now()}
            current={current}
            dataSource={productList}
            setDataSource={setProductList}
            index={index}
            reloadTable={() => actionRef.current?.reload()}
          />
        );
      }
    },
    {
      forTabKeys: ["invalid"],
      hideInSearch: true,
      title: (
        <div style={{ textAlign: "center" }}>
          {intl.formatMessage({ id: "collection.商品失效原因" })}
        </div>
      ),
      renderText: (_text: any, record: { invalidReason: string[] }, _index: any, _action: any) => {
        return (
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            {record.invalidReason.map((reason) => (
              <div key={reason}>{reason}</div>
            ))}
          </div>
        );
      }
    },
    {
      forTabKeys: ["valid", "invalid"],
      hideInSearch: true,
      title: (
        <div style={{ textAlign: "center" }}>{intl.formatMessage({ id: "button.Action" })}</div>
      ),
      valueType: "option",
      key: "option",
      fixed: "right",
      width: 96,
      align: "center",
      renderText: (_text: any, record: any, _index: any, _action: any) => [
        <Popconfirm
          key='del'
          title={intl.formatMessage({ id: "tip.ConfirmDelete" })}
          onConfirm={() => handleDeleteRow(record)}
          onCancel={() => {}}
          okText={intl.formatMessage({ id: "button.Confirm" })}
          cancelText={intl.formatMessage({ id: "button.Cancel" })}
          okButtonProps={{ loading: delItemLoading }}
        >
          <a
            onClick={(e) => {
              e.preventDefault();
            }}
            style={{ color: "red" }}
          >
            {intl.formatMessage({ id: "ttSample.移除" })}
          </a>
        </Popconfirm>
      ]
    },
    {
      forTabKeys: ["pending"],
      hideInSearch: true,
      title: (
        <div style={{ textAlign: "center" }}>{intl.formatMessage({ id: "button.Action" })}</div>
      ),
      valueType: "option",
      key: "option",
      fixed: "right",
      // width: 96,
      align: "center",
      renderText: (_text: any, record: any, _index: any, _action: any) => [
        <Popconfirm
          key='puton'
          title={
            <div style={{ maxWidth: "200px" }}>
              <div>{intl.formatMessage({ id: "collection.确认商品上架至商品集" })}</div>
              <div
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({
                    id: "collection.商品上架后商品集用户可浏览/复制链接/申样该商品，你还要继续吗？"
                  })
                }}
              />
            </div>
          }
          onConfirm={() => handlePutOnRow(record)}
          onCancel={() => {}}
          okText={intl.formatMessage({ id: "button.Confirm" })}
          cancelText={intl.formatMessage({ id: "button.Cancel" })}
          okButtonProps={{ loading: putOnLoading }}
        >
          <a
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            {intl.formatMessage({ id: "collection.上架" })}
          </a>
        </Popconfirm>,
        <Popconfirm
          key='del'
          title={intl.formatMessage({ id: "tip.ConfirmDelete" })}
          onConfirm={() => handleDeleteRow(record)}
          onCancel={() => {}}
          okText={intl.formatMessage({ id: "button.Confirm" })}
          cancelText={intl.formatMessage({ id: "button.Cancel" })}
          okButtonProps={{ loading: delItemLoading }}
        >
          <a
            onClick={(e) => {
              e.preventDefault();
            }}
            style={{ color: "red" }}
          >
            {intl.formatMessage({ id: "ttSample.移除" })}
          </a>
        </Popconfirm>
      ]
    }
  ]
    .filter((value) => value.forTabKeys.includes(tabKey))
    .map((value) => {
      const { forTabKeys, ...others } = value;
      return others;
    }) as ProColumns<any>[];

  const handleSearch = () => {
    // actionRef.current?.reload();
    const word = searchWord.trim();
    const newProductList = (originProductListRef.current || []).filter((item: any) => {
      const { productName, productId } = item;
      return productName.includes(word) || productId.includes(word);
    });
    setProductList(newProductList);
  };

  const handleTableRequest = async (params: any = {}, _sort: any, _filter: any) => {
    console.log("params", params);
    const convertValues = handlePostData(params);
    const requestResult: any = await userManage.requestItemSetProductDetail({
      ...convertValues,
      itemSetId: current.id || "",
      pageNo,
      pageSize,
      itemStatus: {
        valid: 0,
        invalid: 1,
        pending: 2
      }[tabKey]
      // queryParam: searchWord,
    });
    if (requestResult.code === 200 && requestResult.result) {
      const { list, total } = requestResult.result || {};
      // (list || []).forEach((item: any, index: number) => {
      //   item.itemSort = index + 1;
      // });
      originProductListRef.current = cloneDeep(list);
      const word = searchWord.trim();
      const newProductList = (list || []).filter((item: any) => {
        const { productName, productId } = item;
        return productName.includes(word) || productId.includes(word);
      });
      setProductList(newProductList);

      if (newProductList.length > 0) {
        onShareProductReady?.(newProductList[0]);
      }

      return {
        data: newProductList || [],
        success: true,
        total: total || 0
      };
    }
    return {
      data: [],
      success: false,
      total: 0
    };
  };

  const handleBatchRemove = () => {
    modal.confirm({
      title: intl.formatMessage({ id: "ttSample.确认移除商品" }),
      icon: <ExclamationCircleOutlined />,
      content: (
        <div
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage(
              { id: "ttSample.您正在移除2个商品，你还要继续吗？" },
              { num: selectProductIds.length }
            )
          }}
        />
      ),
      okText: intl.formatMessage({ id: "button.Confirm" }),
      cancelText: intl.formatMessage({ id: "button.Cancel" }),
      onOk() {
        setDelLoading(true);
        userManage
          .requestUpdateItemSetProduct({
            type: 0,
            itemSetId: current.id || "",
            itemIdList: selectProductIds
          })
          .then((result: any) => {
            if (result.code === 200) {
              setSelectProductIds([]);
              actionRef.current?.reload();
              message.success(intl.formatMessage({ id: "ttSample.删除成功！" }));
              onUpdateTabTitles?.();
            }
          })
          .catch((err: any) => {
            console.log(err);
          })
          .finally(() => {
            setDelLoading(false);
          });
      }
    });
  };

  const handleBatchPutOn = () => {
    modal.confirm({
      title: intl.formatMessage({ id: "collection.确认商品上架至商品集" }),
      icon: <ExclamationCircleOutlined />,
      content: (
        <div
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage({
              id: "collection.商品上架后商品集用户可浏览/复制链接/申样该商品，你还要继续吗？"
            })
          }}
        />
      ),
      okText: intl.formatMessage({ id: "button.Confirm" }),
      cancelText: intl.formatMessage({ id: "button.Cancel" }),
      onOk() {
        setPutOnLoading(true);
        userManage
          .requestEffectiveItemSetProduct({
            type: 1,
            itemSetId: current.id || "",
            itemIdList: selectProductIds
          })
          .then((result: any) => {
            if (result.code === 200) {
              setSelectProductIds([]);
              actionRef.current?.reload();

              if (result.result.failedCount === 0) {
                message.success(
                  intl.formatMessage(
                    { id: "collection.count个商品均已上架！" },
                    {
                      count: result.result.successCount
                    }
                  )
                );
              } else {
                message.warning(
                  intl.formatMessage(
                    {
                      id: "collection.successCount个商品已上架，failedCount个商品已失效，请前往失效商品查看！"
                    },
                    {
                      successCount: result.result.successCount,
                      failedCount: result.result.failedCount
                    }
                  )
                );
              }

              onUpdateTabTitles?.();
            }
          })
          .catch((err: any) => {
            console.log(err);
          })
          .finally(() => {
            setPutOnLoading(false);
          });
      }
    });
  };

  const handleReset = () => {
    setSearchWord("");
    flushSync(() => {
      actionRef.current?.reload();
    });
  };

  const handleImportSuccess = () => {
    handleReset();
    onUpdateTabTitles?.();
  };

  const onAddProducts = (ids: number[]) => {
    setAddProductsButtonLoading(true);
    userManage
      .requestUpdateItemSetProduct({
        itemIdList: ids,
        itemSetId: current.id || "",
        type: 1
      })
      .then((res: any) => {
        const { code, success } = res;
        if (code === 200 && success) {
          setSelectProductIds([]);
          actionRef.current?.reload();
          message.success(intl.formatMessage({ id: "ttSample.添加成功" }));
          onUpdateTabTitles?.();
        }
      })
      .finally(() => {
        setAddProductsButtonLoading(false);
      });
  };

  return (
    <div className='inner'>
      <div className={styles.operate_container}>
        {/* <Space>
          <Input value={searchWord} onPressEnter={handleSearch} onChange={(e: any) => setSearchWord(e.target.value)} style={{ width: 256 }} placeholder={intl.formatMessage({ id: "ttSample.请输入商品ID或商品名称" })} />
          <Button onClick={handleReset}>{intl.formatMessage({ id: "button.Reset" })}</Button>
          <Button onClick={handleSearch} type='primary'>
            {intl.formatMessage({ id: "button.Search" })}
          </Button>
        </Space> */}
        {/* <Space>
          <Button
            loading={delLoading}
            disabled={selectProductIds.length === 0}
            onClick={handleBatchRemove}>
            {intl.formatMessage({ id: "ttSample.批量移除" })}
          </Button>
          {
            ["valid"].includes(tabKey) &&
            <Button onClick={handleAddProducts} onClick2={() => setAddProductsModalVisible(true)} type='primary' loading={addProductsButtonLoading}>
              {intl.formatMessage({ id: "ttSample.添加商品" })}
            </Button>
          }
          {
            ["valid"].includes(tabKey) && <ImportButton handleImportSuccess={handleImportSuccess} current={current} />
          }
          {
            ["pending"].includes(tabKey) &&
            <Button
              loading={putOnLoading}
              disabled={selectProductIds.length === 0}
              onClick={handleBatchPutOn}
              type='primary'>
              {intl.formatMessage({ id: "collection.批量上架" })}
            </Button>
          }
        </Space> */}
      </div>
      <ProTable<any>
        rowKey='id'
        dataSource={productList}
        scroll={["valid", "pending"].includes(tabKey) ? { x: 2000 } : {}}
        columns={columns}
        actionRef={actionRef}
        formRef={formRef}
        request={handleTableRequest}
        editable={{
          type: "multiple"
        }}
        options={false}
        dateFormatter='string'
        search={{
          labelWidth: "auto",
          searchText: intl.formatMessage({ id: "button.Search" }),
          resetText: intl.formatMessage({ id: "button.Reset" }),

          collapseRender: () => null,
          collapsed: false,
          optionRender: (searchConfig, props, dom) => {
            return [
              ...dom,
              <Space key='2'>
                <Button
                  loading={delLoading}
                  disabled={selectProductIds.length === 0}
                  onClick={handleBatchRemove}
                >
                  {intl.formatMessage({ id: "ttSample.批量移除" })}
                </Button>
                {["valid"].includes(tabKey) && (
                  <Button
                    onClick={handleAddProducts}
                    type='primary'
                    loading={addProductsButtonLoading}
                  >
                    {intl.formatMessage({ id: "ttSample.添加商品" })}
                  </Button>
                )}
                {["valid"].includes(tabKey) && (
                  <ImportButton handleImportSuccess={handleImportSuccess} current={current} />
                )}
                {["pending"].includes(tabKey) && (
                  <Button
                    loading={putOnLoading}
                    disabled={selectProductIds.length === 0}
                    onClick={handleBatchPutOn}
                    type='primary'
                  >
                    {intl.formatMessage({ id: "collection.批量上架" })}
                  </Button>
                )}
              </Space>
            ];
          }
        }}
        pagination={{
          showQuickJumper: true,
          current: pageNo,
          pageSize: pageSize,
          defaultPageSize: 10,
          showSizeChanger: true,
          onChange: (page, pageSize) => {
            setPageNo(page);
            setPageSize(pageSize);
          },
          showTotal(total, range) {
            return `${intl.formatMessage({ id: "common.total" })} ${total}`;
          }
        }}
      />
      {contextHolder}
      <SelectProductsModal
        visible={addProductsModalVisible}
        onHide={() => setAddProductsModalVisible(false)}
        onSelected={onAddProducts}
      />
    </div>
  );
};
